@import "../modules/colors";

footer.lg {
  position: fixed !important;
  bottom: 0;
  background-color: black;
  height: 5%;
  background-color: rgba(0,0,0,.8);
  color: white;
  //padding: 8px 0
  a.selectedLang {
    display: block;
    margin: 5px;
    img {
      width: 24px; } }
  #lang {
    background-color: black;
    text-align: center;
    // border: none
    // border-bottom: 1px solid #ccc
    img {
      width: 24px; } }
  .f-dropdown li:focus, .f-dropdown li:hover {
    background-color: $color5; }
  .bottom-menu a {
    //font-size: .9em
    padding: .5em !important;
    //border-radius: 5px
    line-height: 2.3em;
    //padding: 0 0.9375rem
    &:hover {
      background-color: $color4;
      text-decoration: none !important;
      transition: all .3s ease-out; } }
  .barCenter {
    margin: .5em auto !important; }

  .phone, .email {
    font-size: .8em;
    a {
      font-size: 1em; } }
  .social-media-lg {
    li {
      padding: 0px 5px !important; } }
  .copyrights {
    font-size: .7em;
    a {
      color: $color5 !important;
      &:hover {
        text-decoration: underline; } } } }


@media (max-width: 1300px) {
  footer.lg {
    .phone {
      font-size: 10px; } } }

// @media (max-width: 1100px)
//   footer.lg
//     .copyrights
//       font-size: .6em



// MD FOOTER

footer.md {
  padding: 0 10px;
  .pnl {
    padding: 20px; }
  // background-color: rgba(0,0,0,.8)
  .footer-menu, .social-media-sm, .info,.copyrights {
    margin: 40px 0 0;
    text-align: center;
    color: white;
    li {
      list-style: none;
      line-height: 2em; } }

  .footer-menu {
    font-size: 1.4em; }
  .social-media-sm, .info {
    font-size: .8em; }
  .footer-menu {
    li {
      a {
        padding: .5em;
        // border-radius: 5px
        &:hover {
          background-color: $color4;
          text-decoration: none !important; } } } }
  .social-media-sm {
    li {
      display: inline;
      margin: 0 20px; } }

  .copyrights {
    font-size: .7em;
    a {
      color: $color5 !important; } } }
