@import "partials/default";
@import "partials/sidebars";
@import "partials/footer";


.pnl {
  background-color: rgba(0,0,0,.8);
  border: none;
  color: white;
  h3 {
    color: white; }

  p {
    font-size: 1em;
    text-align: justify;
    margin-bottom: 0;
    color: white; } }

.main {
  padding: 30px 30px;
  .pnl {
    padding: 50px;
    position: fixed;
    overflow-y: auto;
    min-height: 50%;
    max-height: 85%;
    top: 60px; } }

.main-md {
  padding: 40px 10px;
  .pnl {
    padding: 20px; } }
