
.sidebar {
  position: fixed;
  overflow-y: auto;
  height:  95%;
  top: 0;
  bottom: 0;
  background-color: rgba(0,0,0,.8);
  color: white;
  padding: 30px 0px;
  border-bottom: 2px solid rgb(0,0,0);
  .logo {
    text-align: center;
    //a img
 } }    ////margin: 30px 0px





.logo {
  text-align: center;
  a img {
    width: 60%;
    margin: 0 0 30px; } }
//.topbar-logo
//height: 40px


.panel {
  display: none; }



.side-nav {
  text-align:  center;
  li {
    padding: 0px;
    margin-bottom: 0px;
    -webkit-transition:  background-color .4s ease-out;
    transition:  background-color .4s ease-out;
    &.on {
      background-color: $color5; }
    &.active {
      background-color: $color5; }
    &:hover:not(.active) {
      -webkit-transition:  background-color .4s ease-out;
      transition:  background-color .4s ease-out;
      background-color: $color5; }
    > a {
      font-size: 1.3em;
      text-decoration: none !important; }
    .sub-menu {
      margin: 0;
      background: black;
      li {
        list-style: none;
        &.on {
          background-color: $color5; }
        &.active {
          background-color: $color6; }
        &:hover:not(.active) {
          -webkit-transition:  background-color .4s ease-out;
          transition:  background-color .4s ease-out;
          background-color: $color6; }
        > a {
          font-size: 1.1em;
          font-style:  italic;
          color: $color7 !important; } } } } }






// lang Sidebar

//.right-side
//  padding: 0
//  z-index: 1
//  .lang-elem
//    margin: 30px
//    padding: .7em
.lang-list {
  position: absolute;
  bottom: 3%;
  width: 100%;
  // top: 52%
  margin: 0px;
  text-align: center;
  li {
    //margin: 0 .2em
    list-style: none;
    display: inline;
    a {
      padding: .6em .4em;
      //border-radius: 4px
      img {
        width: 24px; }
      &:hover {
        background-color: $color4;
        transition: background-color .3s ease-out;
        -webkit-transition: background-color .3s ease-out; }
      &.act {
        background-color: $color4; } } } }


.hasChildren:after {
  float: left; }


.side-nav {
  text-align:  center; }




// MD TOP BAR

.top-bar-section .left li img {
  width: 24px; }

.top-bar-section li.active.lang a {
  background-color: $color5 !important; }

.fi-list {
  font-size: 1.3em; }



.lang-list-sm {
  margin: 0px;
  li {
    list-style: none;
    display: inline; }
  li:nth-child(n+2) {
    margin-top: -20px;
    a {
      padding: .6em;
      border-radius: 4px;
      img {
        width: 24px; }
      &:hover, .act {
        background-color: $color4;
        transition: background-color .3s ease-out;
        -webkit-transition: background-color .3s ease-out; } } } }






// topSidebar

.top-bar {
  background-color: rgba(0,0,0,.8);
  height: auto;
  ul.title-area {
    margin-right: 20px;
    li.name > a > img {
      height: 45px; } }
  .top-bar-section {
    ul.left {
      li {
        height: 40px;
        background-color: transparent !important;
        -webkit-transition:  background-color .3s ease-out;
        transition:  background-color .3s ease-out;
        &.active {
          background-color: $color5 !important;
          a:not(:first-child) {
            padding: 0 .6em; } }

        &:hover:not(.active) {
          -webkit-transition:  background-color .3s ease-out;
          transition:  background-color .3s ease-out;
          background-color: $color5 !important; }
        a {
          background-color: transparent !important;
          font-size: 1.1em;
          padding: 0 .6em;
          line-height: 2.8125rem;
          &:hover {
            text-decoration: none !important; } }
        &.active:not:first-child {
          background-color: $color4 !important; }
        &.active:first-child:hover {
          background-color: #008CBA !important;
          transition: all .3s ease-out; } }
      li:first-child {
        a {
 } } }          //padding: 0 .6em

    ul.right {
      li {
        height: 40px;
        background: transparent;
        a {
          //line-height: 1em
          background: transparent;
          img {
            width: 24px; } }
        a.topLogo {
          line-height: 1em;
          img {
            height: 40px;
            width: auto; } } } }
    ul.layout2 {
      li {
        background-color: transparent !important;
        -webkit-transition:  background-color .3s ease-out;
        transition:  background-color .3s ease-out;
        a {
          background-color: transparent !important;
          &:hover {
            -webkit-transition:  background-color .3s ease-out;
            transition:  background-color .3s ease-out;
            background-color: $color5 !important; } } }
      li.active {
        a {
          background-color: $color5 !important; } } }


    ul.dropdown {
      background-color: rgba(0,0,0,.8); } } }



.top-bar-section {
  ul {
    display: inline-flex;
    width: auto;
    li {
      background-color: transparent;
      a {
        padding: 0; } } } }
