@import "../modules/colors";

// @import url(https://fonts.googleapis.com/css?family=Lora:400,400italic,700,700italic);


// a, h1,h2,h3,h4,h5, p, span
//// font-family: "Lora"

a,p {
  font-weight: 300; }
a {
  color: white !important;
  &:hover {
    text-decoration: underline !important; } }

i {
  font-size: .7em; }

span.imp {
  color: $color2; }

.color {
  background-color: $color3;
  &:hover {
    background-color: darken($color3,10%); } }


//Loader

.se-pre-con {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-image: url("../img/loader.GIF");
  background-repeat: no-repeat;
  background-position: center;
  background-color: #fff; }

//End Loader


.close {
  position: absolute;
  top: 0;
  right: 0;
  margin: 25px;
  i {
    font-size: 1.2em; } }
